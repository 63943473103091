<template>
  <v-layout max-width class="flex-column">
    <div class="flex-y-center flex-justify-between px-4 py-2 white mb-3" style="">
      <div>
        <v-btn dark small color="#00bea9" depressed min-width="32" height="30" style="padding:0 8px;" @click="addShow">
          <v-icon size="16">mdi-plus</v-icon>新增节点
        </v-btn>
      </div>
      <div class=" flex-y-center" />
    </div>
    <div class=" white">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width pa-4"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="listLoading"
      >
        <!-- <template v-slot:item="{expand,item}"> -->

        <template v-slot:item.rowIndex="{index}">
          {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
        </template>
        <template v-slot:item.tagColor="{item}">
          <div style="width:50px;height:16px;" :style="{background:item.tagColor}" />
        </template>
        <template v-slot:item.active="{item}">
          <a class=" mr-2" @click="showEdit(item)">编辑</a>
          <a class=" mr-2" @click="showDel(item.id)">删除</a>
        </template>
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end ">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList()" @changePageSize="changePageSize" />
      </div>
    </div>
    <!-- 新增 编辑 组织角色 -->
    <v-dialog v-model="dialog.show" width="600">
      <form @submit.prevent="submitAdd()">
        <v-card>
          <v-card-title class="font-weight-bold">{{ dialog.id?'编辑节点':'新增节点' }}</v-card-title>
          <v-divider />
          <v-card-text class=" py-5">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>节点名称：</div>
                <input
                  v-model="dialog.data.tagName"
                  class="flex-1 default-input py-1 subtitle-1"
                  placeholder="请输入节点名称"
                  required
                  type="text"
                >
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>颜色：</div>
                <v-menu
                  v-model="dialog.colorShow"
                  :close-on-content-click="false"
                  bottom
                  max-height="350"
                  min-width="200"
                  offset-overflow
                  transition="slide-y-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="default-input grey--text text--lighten-1 subtitle-1 flex-y-center"
                      style="height:32px;position: relative;"
                      color="primary"
                      :style="{background:(dialog.data.tagColor)||'#fff'}"
                      @click="dialog.color=dialog.data.tagColor"
                      v-on="on"
                    >
                      {{ dialog.data.tagColor?'':'请选择颜色' }}
                      <input v-model="dialog.data.tagColor" class=" flex-1" style="height:1px; opacity: 0;position: absolute" required>
                    </div>
                  </template>
                  <v-card class=" elevation-0">
                    <v-color-picker
                      v-if="dialog.show"
                      v-model="dialog.color"
                      class=" elevation-0"
                      dot-size="30"
                    />
                    <div class=" px-3 pb-3 body-2  flex-y-center flex-justify-end">
                      <v-btn small depressed class="mr-2" height="30" @click="dialog.colorShow=false,dialog.color='',dialog.data.tagColor=''">清空</v-btn>
                      <v-btn
                        small
                        depressed
                        color="primary"
                        height="30"
                        @click="dialog.data.tagColor=dialog.color,dialog.colorShow=false"
                      >确认</v-btn>
                    </div>
                  </v-card>
                </v-menu>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn width="80" height="30" class depressed @click="dialog.show = false">取消</v-btn>
                <v-btn
                  width="80"
                  :loading="btnLoading"
                  type="submit"
                  class="ml-3"
                  height="30"
                  color="primary"
                  depressed
                >确定</v-btn>

              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 新增 编辑 组织角色 end -->

  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '节点名称', value: 'tagName' },
        { text: '节点颜色', value: 'tagColor', sortable: false },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      listLoading: false,
      btnLoading: false,
      // color:"#D53434",
      dialog: {
        show: false,
        colorShow: false,
        type: '',
        color: '',
        data: {

        }
      }
    }
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    getList() {
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageSize = this.search.pageSize
      search.pageNo = this.search.pageNo
      this.$http.get(this.$api.progressEventTagList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result.records || []
        this.total = res.result.total
      })
    },
    // 确认删除弹窗
    showDel(id) {
      // this.dialog.show=true
      // this.dialog.data={...item}
      // // this.dialog.data.tagColor
      // this.dialog.color={hex:this.dialog.data.tagColor||''}

      // if(confirm("是否确认删除？")){
      //   this.$http.delete(this.$api.progressEventTagDelete,{data:{id:id}}).then(res => {
      //       this.$message.success(res.message)
      //       this.getList()
      //   });
      // }
      this.$confirm({ text: '是否确认删除？' }).then((vm) => {
        vm.loading = true
        this.$http.delete(this.$api.progressEventTagDelete, { data: { id: id }}).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          // this.getList()
          this.initList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 编辑弹窗
    showEdit(item) {
      this.dialog.show = true
      this.dialog.data = { ...item }
      // this.dialog.data.tagColor
      // this.dialog.color={hex:this.dialog.data.tagColor||''}
      this.dialog.color = this.dialog.data.tagColor || '#D53434'
      // this.dialog.color=this.dialog.data.tagColor||""
    },
    addShow() {
      this.dialog.show = true
      // this.dialog.data={tagColor:"#D53434"}
      this.dialog.data = { tagColor: '' }
      this.dialog.color = this.dialog.data.tagColor
      // // this.dialog.data={tagColor:"#D53434"}
      // this.$set(this.dialog,'color','#ffffff')
      // this.dialog.show=true
      // // this.dialog.data={tagColor:""}
      // this.$forceUpdate()
      // this.dialog.color=''
      // // this.$set(this.dialog,'color','')
    },
    delShow(id) {
      // if(confirm("是否确认删除？")){
      //   this.$http.delete(this.$api.progressEventTagDelete,{data:{id:id}}).then(res => {
      //         this.$message.success(res.message)
      //         this.getList()
      //   });
      // }
      this.$confirm({ text: '是否确认删除？' }).then((vm) => {
        vm.loading = true
        this.$http.delete(this.$api.progressEventTagDelete, { data: { id: id }}).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          // this.getList()
          this.initList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    submitAdd() {
      let url = this.$api.progressEventTagAdd
      let method = 'post'
      if (this.dialog.data.id) {
        url = this.$api.progressEventTagEdit
        method = 'put'
      }
      // this.dialog.data.tagColor=this.dialog.color.hex||''
      // this.dialog.data.tagColor=this.dialog.color||''
      this.btnLoading = true
      this.$http[method](url, { data: { ...this.dialog.data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.dialog.data = {}
        this.btnLoading = false
        this.getList()
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:100px;
    color: #777;
}
</style>
